<template>
    <div class="walking-paragraph">
        <div class="walking-wrapper">
            <div class="text-single">
                            <span
                                v-for="(item,index) in walkingParagraph"
                                :key="index"
                                class="text js-text luxury">{{ item }}</span>
            </div>
            <div class="text-single">
                            <span
                                v-for="(item,index) in walkingParagraph"
                                :key="index"
                                class="text js-text luxury">{{ item }}</span>
            </div>
        </div>
    </div>
</template>

<script setup>
import {inject, onMounted, ref, watch} from "vue";
import gsap from "gsap";

let prop = defineProps({
    walkingParagraph: {
        type: Object,
        required: true
    }
})
let container = ref(null)
let containerWidth = ref(null)
const delta = 800
const {direction, eventWatcher} = inject('textSwiper')
const setContainers = () => {
    container.value = document.querySelector('.text-single')
    containerWidth.value = container.value?.getBoundingClientRect()?.width
}
const wrap = (value, min, max) => {
    let v = value - min;
    let r = max - min;

    return ((r + v % r) % r) + min;
}
const swipe = () => {
    gsap.to('.text-single', {
        // x: '+=' + containerWidth.value,
        x: `+=${-direction.value * delta}`,
        duration: 1,
        ease: 'Power1.easeOut',
        modifiers: {
            x: (x) => {
                return `${wrap(parseFloat(x), -containerWidth.value, containerWidth.value)}px`
            }
        },
    })
}
const setContainerPosition = () => {
    let secondContainer = document.querySelector('.text-single:last-child')
    gsap.set(secondContainer, {
        x: containerWidth.value
    })
}

watch(() => eventWatcher, () => {
    swipe()
}, {deep: true});
onMounted(() => {
    setContainers()
    setContainerPosition()
})
</script>

<style lang="scss" scoped>
.walking-paragraph {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    color: $primaryNavy;

    .walking-wrapper {
        display: flex;
        align-items: center;
        overflow: hidden;
        width: 100%;
        height: 100px;
        @media only screen and (max-width: 1023px) {
            height: 85px;
        }
    }

    .text-single {
        position: absolute;
        display: flex;
        align-items: center;
        width: max-content;
        will-change: transform;

        .text {
            padding: 0;
            font-weight: 400;
            display: inline-block;
            font-size: 55px;
            text-transform: uppercase;
            opacity: 0.07;
            margin-right: 55px;
            @media only screen and (max-width: 1023px) {
                font-size: 34px;
            }
            &:last-child {
                margin-right: 0;
            }
        }

    }
}

</style>

<style lang="scss">
body {
    &.is-dark {
        .walking-paragraph {
            color: white;
        }
    }
}

</style>
